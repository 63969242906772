/* color body */
body{
  background-color: var(--background-color) !important;
}

/* color panel arriba */
.fondo {
    background-color: var(--background-color);
  }

/* color fondo acordeon */
.accordion-item{
  background-color: var(--background-color) !important;
}

/* color boton acordeon */
.accordion-button{
  background-color: var(--background-color) !important;
}

/* color letras desplegables */
.accordion-button:not(.collapsed){
color:var(--secondary-color) !important;
}
.accordion-button{
  color: antiquewhite !important;
}
.accordion-button:focus{
  border-color: none !important;
}

/* Color fondo tab activa*/
.nav-tabs .nav-link.active {
background-color: var(--secondary-color) !important;
color:white !important;
}

/* color letra tab no activa */
.nav-link{
  color: var(--secondary-color) !important;}

/* logo mirringa fondo */

.imgFondo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  margin-top: -125px; /* Half the height */
  margin-left: -125px; /* Half the width */
  z-index: 10000000000 !important; 
  opacity: 10%;
  pointer-events:none;
}

.imagenInfo {
  position: absolute;
  top: 0.3em;
  right: 0em;
  width: 3.5em !important;
  z-index: 1000 !important; 
  pointer-events:all;
}

.space {
  padding: 15px;
}

.center-horizontal{
  display: flex;
  justify-content: center;
}

.list-group{
  border-radius: 2rem !important;
}

.precio{
  color: var(--secondary-color)
}

.nav-tabs {
	border-bottom: 1px solid var(--secondary-color) !important;
}

.nav-item{
  border-color: none !important;
}

.separator{
  border-bottom: 2px dotted var(--secondary-color) !important;
}