:root {
  --background-color: #1d1c1e;
  --secondary-color: #F77141;
}

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'),url('fonts/Comfortaa/static/Comfortaa-Regular.ttf') format('truetype'),
}

body {
  margin: 0;
  font-family: 'Comfortaa',sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
